var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mx-2"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.allows_crud)?_c('button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_methodologicalstrategy'),expression:"'mesh.add_methodologicalstrategy'"}],staticClass:"btn btn-secondary btn-sm btn-add",on:{"click":function($event){return _vm.$bvModal.show(`new-methodological-strategy-modal`)}}},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames( "mesh.methodologicalstrategy", false, "Estrategia Metodológica" ))+" ")]):_vm._e()])]),_c('h3',{staticClass:"title-header noprint"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.methodologicalstrategy", true, "Estrategias Metodológicas" ))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.methodologicalstrategy',"allows_crud":_vm.allows_crud}})],1),_c('GenericBTable',{attrs:{"items":_vm.methodological_strategies,"fields":_vm.methodologicalStrategyFields,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"show_pagination":true,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"display_id":'methodological_strategies',"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"emitChangeSearchField":_vm.changeInputSearch,"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([(_vm.allows_crud)?{key:"cell(actions)",fn:function(row){return [_c('button-edit',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_methodologicalstrategy'),expression:"'mesh.change_methodologicalstrategy'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Editar ${_vm.$getVisibleNames(
              'mesh.methodologicalstrategy',
              false,
              'Estrategia Metodológica'
            )}`
          ),expression:"\n            `Editar ${$getVisibleNames(\n              'mesh.methodologicalstrategy',\n              false,\n              'Estrategia Metodológica'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`edit-methodological-strategy-modal-${row.item.id}`)}}}),_c('button-delete',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_methodologicalstrategy'),expression:"'mesh.delete_methodologicalstrategy'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Eliminar ${_vm.$getVisibleNames(
              'mesh.methodologicalstrategy',
              false,
              'Estrategia Metodológica'
            )}`
          ),expression:"\n            `Eliminar ${$getVisibleNames(\n              'mesh.methodologicalstrategy',\n              false,\n              'Estrategia Metodológica'\n            )}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteMethodologicalStrategy(row.item.id)}}}),_c('b-modal',{attrs:{"id":`edit-methodological-strategy-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
            'mesh.methodologicalstrategy',
            false,
            'Estrategia Metodológica'
          )}`,"size":"lg","hide-footer":""}},[_c('MethodologicalStrategyForm',{attrs:{"MethodologicalStrategy":row.item,"institution_id":row.item.school,"show_title":false},on:{"updated":_vm.slotUpdatedMethodologicalStrategy}})],1)]}}:null],null,true)})],1),_c('b-modal',{attrs:{"id":`new-methodological-strategy-modal`,"hide-footer":true,"title":`Crear ${_vm.$getVisibleNames(
      'mesh.methodologicalstrategy',
      false,
      'Estrategia Metodológica'
    )}`,"size":"lg"}},[_c('MethodologicalStrategyForm',{attrs:{"institution_id":this.user.school},on:{"created":_vm.slotCreatedMethodologicalStrategy}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }